import Vue from "vue";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import axios from "axios";

function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
Vue.mixin({
  methods: {
    formatPrice(value, currency = "đ") {
      if (value !== undefined && value !== null) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + currency;
      }
      return 0 + currency;
    },
    formatDate(time, format = "D/M/YYYY") {
      if (time !== undefined && time !== null) {
        return dayjs(time).locale("vi").format(format);
      }
      return "";
    },
    exportData(requestUrl, params) {
      try {
        axios({
          url: requestUrl,
          method: "GET",
          responseType: "blob",
          params,
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            const filename = response.headers["content-disposition"]
              .split(";")
              .find((n) => n.includes("filename="))
              .replace("filename=", "")
              .trim();
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((errors) => {
            return errors;
            // console.log(errors);
          });
      } catch (e) {
        return e;
        // console.log(e);
      }
    },
    formatDateString(str) {
      if (typeof str !== "string") {
        let date = new Date(str);
        if (date != "Invalid Date") {
          return this.formatDate(str, "MM/DD/YYYY");
        }
      }
      let dateArr = str.split("/");
      return `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
    },
      /**
   * Format Datetime in SQL format
   *
   * @param   {Date}  date  Date to be formated
   *
   * @return  {String}        Formated Datetime. Ex: "2020-08-13 10:00:00"
   */
  formatDateTime(date) {
    let year = date.getFullYear();
    let month = twoDigits(date.getMonth() + 1);
    let day = twoDigits(date.getDate());
    let hour = twoDigits(date.getHours());
    let minute = twoDigits(date.getMinutes());
    let second = twoDigits(date.getSeconds());
    return `${[year, month, day].join("-")} ${[hour, minute, second].join(":")}`;
  },
    toMySQLFormat(date) {
      if (!isValidDate(date)) {
        date = new Date();
      }
  
      return (
        date.getUTCFullYear() +
        "-" +
        twoDigits(1 + date.getUTCMonth()) +
        "-" +
        twoDigits(date.getUTCDate()) +
        " " +
        twoDigits(date.getUTCHours()) +
        ":" +
        twoDigits(date.getUTCMinutes()) +
        ":" +
        twoDigits(date.getUTCSeconds())
      );
    },
  },
});
